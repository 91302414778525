.projectDetails {
  .detailsLarge {
    .firstSection {
      img {
        width: 100%;
        height: 35vh;
        object-fit: cover;
        object-position: center bottom;
      }
    }

    .secondSection {
      img {
        width: 100%;
        height: 75vh;
        object-fit: fit;
      }
    }

    .detailContent {
      padding: 10px 10px !important;
    }
  }

  .detailsMedium {
    background-color: black;
    margin-top: 20px;

    .firstImage {
      width: 100%;

      img {
        width: 98%;
        height: 30vh;
        object-fit: cover;
        object-position: center bottom;
        margin: 10px;
      }
    }

    .details {
      div {
        padding: 10px;
        color: white;
      }
    }

    .secondImage {
      img {
        margin: 10px;
        width: 98%;
      }
    }
  }
}