.projectContent {
    font-family: Garamond, "Times New Roman", serif;
    color: white;
    .projectHeading{
        margin-top: 10px;
    }
}

.projectTabs {
    button {
        color: white !important;
        margin-right: 20px;
        margin-top: 5px;
    }

    .nav-link.active {
        border-color: red !important;
        background-color: black !important;
    }

    .nav-tabs {
        border: none;

        .nav-link {
            border-radius: 0px;
        }
    }

}


@media only screen and (max-width: 600px) {
    .projectTabs button {
        margin-right: 10px;
        margin-top: 5px;
    }
  }
@media only screen and (max-width: 404px) {
    .projectTabs button {
        margin: 5px;
        padding: 4px;
    }
}