.projectAchieved {
  .detailSection {
    background-repeat: no-repeat;
    background-position: 90% top;
    background-size: 300px 300px;
    color: black;
    height: 60vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox, Edge, and IE use standard scrollbar-width */
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* Set to the background color of your container */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent; /* Set to the background color of the scrollbar thumb */
    }
  }
  .backImage {
    background-color: rgba(255, 255, 255, 0.726);
  }

  .color {
    color: red;
  }
  .aSubHeading1 {
    text-align: center;
    font-size: large;
    color: red;
  }
  .aSubHeading2 {
    text-align: center;
  }
  .achievedText {
    p {
      text-align: justify;
    }
  }
  .imageSection {
    img {
      height: 75vh;
      object-fit: contain;
      object-position: center bottom;
    }
  }
}

@media screen and (max-width: 991px) {
  .projectAchieved {
    color: black;
    .row {
      display: flex;
      flex-direction: column-reverse;
    }
    .detailSection {
      height: 100%;
      overflow-y: hidden;
    }
    .imageSection {
      img {
        height: 75vh;
        object-fit: cover;
      }
    }
  }
}
