.contactUs-col1{
    font-family: Garamond, "Times New Roman", serif;
}

.contactUs-title{
    font-size: xxx-large;
}

.contactUs-content{
    color: #aeabab;
    font-size: x-large;
}