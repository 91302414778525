.navbarCss {
    // font-size: large;
    line-height: 2.545em;
    font-weight: 500;
    letter-spacing: .25em;
    // color: #2b2b2b;
    border-bottom: 1px solid black;
    // background-color: aliceblue;
    display: flex;

    .navBrand {
        display: inline-block;
        vertical-align: middle;
        // padding: 0px 15px,
        margin: 0;
        padding: 0;
    }

    .navBrandDiv {
        font-family: 'Garamond';
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .logoText {
            margin-left: 5px;
            letter-spacing: 0px;

            .ameerText {
                margin-bottom: 0px;
                font-size: 1.8rem;
                position: relative;
                bottom: -5px;

                .capsA {
                    font-size: 45px;
                    margin-left: 10px;
                }
            }

            .architectEngineerText {
                font-size: 0.8rem;
            }
        }
    }

    .img-logo {
        width: 80px;
        height: auto;
        object-fit: contain;
    }

    .navbar-toggler {
        flex: 0;
        border: none;
        transition: transform 0.5s ease;
        display: inline-block;
        vertical-align: middle;
    }

    .navbar-toggler:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    .navbar-toggler-icon {
        transition: transform 0.5s ease;
    }

    .navbar-collapse {
        justify-content: flex-end;
    }

    .navLink {
        // display: inline-flex;
        // position: relative;
        color: #625A67;
        // margin-right: 2rem;
        font-family: Garamond, "Times New Roman", serif;
        // padding: 0.5rem 1rem;
        white-space: nowrap;
        text-align: center;
        text-decoration: none !important;
        margin-right: 10px;
    }

    // .navLink.active {
    //     color: #B81D24;
    // }

    .AINavLink {
        color: #e50914;
        font-weight: bold;
    }

    .navBrand:hover .img-logo {
        display: block;
    }
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #B81D24 !important;
}

.img-logo-hover {
    display: none;
}

.navbarCssBlack .navBrand:hover .img-logo-hover {
    display: block;
    width: 80px;
    height: auto;
    object-fit: contain;
}

.navBrand:hover .img-logo {
    display: none;
}

.navbarCssBlack {
    line-height: 2.545em;
    font-weight: 500;
    letter-spacing: .25em;
    // color: #2b2b2b;
    border-bottom: 1px solid white;
    // background-color: aliceblue;
    display: flex;

    .navBrand {
        display: inline-block;
        vertical-align: middle;
        flex: 1;
        margin: 0;
        padding: 0;
    }

    .logoText {
        display: none;
    }

    .img-logo {
        width: 80px;
        height: auto;
        object-fit: contain;
    }

    .navbar-toggler {
        flex: 0;
        border: none;
        transition: transform 0.5s ease;
        display: inline-block;
        vertical-align: middle;
    }

    .navbar-toggler:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    .navbar-toggler-icon {
        transition: transform 0.5s ease;
    }

    .navbar-collapse {
        justify-content: flex-end;
    }

    .navLink {
        // display: inline-flex;
        // position: relative;
        color: white;
        margin-right: 2rem;
        font-family: Garamond, "Times New Roman", serif;
        // padding: 0.5rem 1rem;
        white-space: nowrap;
        text-align: center;
        text-decoration: none !important;
        margin-right: 10px;
    }

    .navLink .dropdown-toggle {
        color: white;
    }

    // .navLink.active {
    //     color: #B81D24;
    // }

    .AINavLink {
        color: #e50914;
        font-weight: bold;
    }

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 767px) {
    .navBrand {
        flex: 1;
    }

    // .img-logo{

    // }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .navBrand {
        flex: 1;
    }
}

// for larger screens
@media (min-width: 992px) {
    .navbarCss .navLink::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #B81D24;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .navbarCss .navLink:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
        color: red;
    }

    .navbarCssBlack .navLink:hover {
        color: red;
    }

}