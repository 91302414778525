.otherProject {
  display: flex;
  flex-direction: column;
}

.otherProjectsDisplay {
  font-size: xx-large;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .otherProjectMaindiv {
    display: flex;
    flex-direction: column-reverse;
  }

  .otherProjectsDisplay {
    text-align: center;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .otherProjectMaindiv {
    display: flex;
    flex-direction: column-reverse;
  }

  .otherProjectsDisplay {
    text-align: center;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .otherProjectMaindiv {
    display: flex;
    flex-direction: column-reverse;
  }

  .otherProjectsDisplay {
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .otherProject {
    margin: 10vh 0 0 0;
  }

  .otherProjectsImg {
    .odd {
      width: 100%;
      display: block;
      aspect-ratio: 1 !important;
      object-fit: fill;
    }

    .even {
      width: 100%;
      display: block;
      aspect-ratio: 1.2 !important;
      object-fit: fill;
    }
  }

  .otherProjectTag {
    position: absolute;
    bottom: 50px;
    display: flex;
    padding-left: 10px;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .otherProject {
    margin: 10vh 0 0 0;
  }

  .otherProjectsImg {
    .odd {
      width: 100%;
      display: block;
      aspect-ratio: 1 !important;
      object-fit: fill;
    }

    .even {
      width: 100%;
      display: block;
      aspect-ratio: 1.2 !important;
      object-fit: fill;
    }
  }

  .otherProjectTag {
    position: absolute;
    bottom: 50px;
    display: flex;
    padding-left: 10px;
  }
}