.efforts-main-title {
    font-size: xxx-large;
}

.spanAnd {
    font-weight: bolder;
}

.efforts-logo-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.efforts-logo-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.efforts-img {
    width: 100%;
    height: auto;
    margin-top: 5%;
}

/* Adjust the layout and styles for small screens */
// @media screen and (max-width: 576px) {
//     .efforts-img {
//         margin-top: 5%;
//     }
// }

/* Adjust the layout and styles for medium screens */
@media screen and (max-width: 991px) {
    .efforts-img {
        margin-top: 5%;
    }

    .efforts-logo {
        width: 100%;
        /* Adjust the width as needed */
        height: auto;
        /* Maintain aspect ratio */
      }
    
    .efforts-below-logo-text {
        text-align: center;
        width: 100%;
        font-style: italic;
        text-decoration: underline;
        padding-left: 10vw;
      }
}

/* Adjust the layout and styles for large screens */
@media screen and (min-width: 992px) {
    .efforts-img {
        margin-top: 5%;
        width: 100%;
        height: 83%;
    }

    /* Adjust the number of images per row */
    // .efforts-img-1,
    // .efforts-img:nth-child(3n+1) {
    //     // width: 33.33%;
    // }

    .efforts-content {
        font-size: larger;
    }

    .efforts-logo-col {
        padding-left: 10vw !important;
    }

    .efforts-logo {
        width: 20vw;
        /* Adjust the width as needed */
        height: auto;
        /* Maintain aspect ratio */
    }

    .efforts-below-logo-text {
        text-align: center;
        width: 100%;
        font-style: italic;
        text-decoration: underline;
        padding-left: 2vw;
    }
}

// .test{
//   width: 80vw; /* Adjust the desired width */
//   height: 100vh; /* Adjust the desired height */
// }