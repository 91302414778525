.black-Background {
    background-color: black;
    min-height: 100vh;
}

.fontWhite {
    font-family: Garamond, "Times New Roman", serif;
    color: white;
}

.fontBlack {
    font-family: Garamond, "Times New Roman", serif;
    color: black;
}

.spanRed {
    color: #e50914;
}

.greyText {
    color: #7f7f7f;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}