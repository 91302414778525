.AIContent-main {
  font-family: Garamond, "Times New Roman", serif;
  color: white;
}

@media screen and (max-device-width: 767px) {

  .AIContent-first-row {
    font-size: x-large;
  }

  .siteLocation {
    font-size: 2vh;
  }

  .h1Contain {
    display: block;
  }

  .AIContent-second-one-row {
    margin-top: 20% !important;
    display: flex;
    flex-wrap: wrap;

    h1 {
      font-size: 5vh;
    }

    .siteLocation {
      display: block;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

  .AIContent-third-one-row {
    margin-top: 20% !important;
    display: flex;
    flex-wrap: wrap;

    h1 {
      font-size: 5vh;
    }

    .siteLocation {
      display: block;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

}

/* For Mobile and Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {

  .AIContent-first-row {
    font-size: x-large;
  }

  .siteLocation {
    font-size: 3vh;
  }

  .h1Contain {
    display: block;
  }

  .AIContent-second-one-row {
    margin-top: 5% !important;

    h1 {
      font-size: 8vh;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

  .AIContent-third-one-row {
    margin-top: 5% !important;

    h1 {
      font-size: 8vh;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

}

// For Desktop
@media screen and (min-device-width: 1024px) {

  .AIContent-first-row {
    font-size: xx-large;
  }

  .siteLocation {
    font-size: 2vh;
  }

  .AIContent-second-row {
    display: flex;
    flex-direction: row;
    // align-items: center;
  }

  .AIContent-second-one-row {
    margin-top: 5% !important;

    h1 {
      font-size: 8vh;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

  .AIContent-third-one-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 30vw !important;
    margin-top: 5% !important;

    h1 {
      font-size: 8vh;
    }

    a {
      color: #7f7f7f;
      font-style: italic;
    }
  }

}