// span {
//     color: #e50914;
// }
.about-us-row {
    margin-top: 2% !important;
    display: flex;
    flex-wrap: wrap;
}

.about-us-content {
    font-family: Garamond, "Times New Roman", serif;
    // margin-left: 5%;
    // margin-right: 5%;
    text-align: center;
    flex: 1;

}

.about-us-first-row {
    font-size: xx-large;
    color: #aeabab;
}

.about-us-second-row {
    color: black;
    margin-top: 2%;
    font-size: large;

}

.about-us-common-row {
    color: black;
    font-weight: bolder;
    font-size: x-large;
    margin-top: 3%;

}

.about-us-seventh-row {
    color: black;
    font-weight: bold;
    font-size: large;
    margin-top: 3%;
}

.about-us-eight-row {
    font-size: large;
}

.image-container {
    height: 100%;
    /* Occupy the full height of the parent container */
    display: flex;
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
}


/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 767px) {
    .ameer-pic {
        height: 50vh;
        width: auto;
    }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .ameer-pic {
        height: auto;
        width: auto;
    }
}

@media (min-width: 1024px) {
    .ameer-pic {
        height: 80vh;
        width: auto;
        object-fit: fit;
    }
}