.mobileCard {
  a {
    text-decoration: none;
  }

  .card {
    color: white;
    background-color: black;
    border: 1px solid white !important;
    margin: 10px;

    img {
      height: 50vh;
    }
  }
}

.mobileRow {
  flex-wrap: nowrap !important;
  align-items: center;
}