.projectImpossible {
  font-family: Garamond, "Times New Roman", serif;
  .imageSection {
    img {
      height: 65vh;
      width: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
  }
  .detailsSection {
    h1 span {
      color: red;
    }
    .heading1 {
      font-size: 60px;
      position: absolute;
      top: 32%;
      left: 30%;
    }
    .heading2{
      font-size: 60px
    }
    .quote {
      .color {
        color: red;
      }
    }
  }
}
