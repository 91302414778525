.mia-main-title {
  color: #aeabab;
  font-size: xxx-large;
}

.mia-logo-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mia-logo-col {
  // padding-left: 10vw !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mia-img {
  width: 100%;
  height: auto;
  margin-top: 5%;
}

/* Adjust the layout and styles for small screens */
// @media screen and (max-width: 576px) {
//   .mia-img {
//     margin-top: 5%;
//   }
// }

/* Adjust the layout and styles for medium screens */
@media screen and (max-width: 991px) {
  .mia-img {
    margin-top: 5%;
  }

  .mia-logo {
    width: 100%;
    /* Adjust the width as needed */
    height: auto;
    /* Maintain aspect ratio */
  }

  .mia-below-logo-text {
    text-align: center;
    width: 100%;
    font-style: italic;
    text-decoration: underline;
    padding-left: 10vw;
  }
}

/* Adjust the layout and styles for large screens */
@media screen and (min-width: 992px) {
  .mia-img {
    margin-top: 5%;
    width: 100%;
    height: 83%;
  }

  /* Adjust the number of images per row */
  // .mia-img-1,
  // .mia-img:nth-child(3n+1) {
  //   // width: 33.33%;
  // }

  .mia-content {
    font-size: larger;
  }

  // .mia-logo-row {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  // }

  // .mia-logo-col {
  //   // padding-left: 10vw !important;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  // }

  .mia-logo {
    width: 20vw;
    /* Adjust the width as needed */
    height: auto;
    /* Maintain aspect ratio */
  }

  .mia-below-logo-text {
    text-align: center;
    width: 100%;
    font-style: italic;
    text-decoration: underline;
    padding-left: 10vw;
  }

}

// .test{
//   width: 80vw; /* Adjust the desired width */
//   height: 100vh; /* Adjust the desired height */
// }