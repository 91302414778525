// span {
//     color: #e50914;
// }

.image-with-text {
    position: relative;
}

.grey-img-logo {
    display: block;
    width: 100%;
    height: auto;
}

.home-content-left-col {
    position: relative;
    text-align: center;
}

.text-inside-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Garamond, "Times New Roman", serif;
    text-align: center;
    font-size: x-large;
    color: white;
    font-style: italic;
    width: 80%;
}

.home-content-right-col {
    font-family: Garamond, "Times New Roman", serif;
    text-align: center;
}

.home-content-first {
    font-size: xxx-large;
    margin-top: 15%;
}

.home-content-second {
    font-size: x-large;
}

.home-content-third {
    font-size: xx-large;
    margin-top: 10%;

    // text-align: center; 
}

.home-content-fourth {
    font-size: xxx-large;
}

// .home-content {
//     position: relative;
//     left: -100%;
//     /* Start offscreen to the left */
//     transition: left 1.5s ease-in-out;
//     /* Transition property */
// }

// .home-content.active {
//     left: 0;
//     /* Bring it back to its original position */
// }


@media only screen and (max-width: 480px) {
    .text-inside-img {
        font-size: large;
    }

    .home-content-right-col {
        font-family: Garamond, "Times New Roman", serif;
        text-align: center;
    }

    .home-content-first {
        font-size: xx-large !important;
        margin-top: 5% !important;
    }

    .home-content-second {
        font-size: larger !important;
    }

    .home-content-third {
        font-size: x-large !important;
        margin-top: 10%;

        // text-align: center; 
    }

    .home-content-fourth {
        font-size: xx-large !important;
    }
}

@media only screen and (max-width: 280px) and (max-aspect-ratio: 1/2) {
    .text-inside-img {
        font-size: small;
        top: 45%
    }

    .home-content-right-col {
        font-family: Garamond, "Times New Roman", serif;
        text-align: center;
    }

    .home-content-first {
        font-size: x-large !important;
        margin-top: 5% !important;
    }

    .home-content-second {
        font-size: large !important;
    }

    .home-content-third {
        font-size: larger !important;
        margin-top: 10%;

        // text-align: center; 
    }

    .home-content-fourth {
        font-size: x-large !important;
    }
}