.mobileTabContents {
  .imageSec {
    img {
      width: 100%;
      height: 60vh;
      object-fit: cover;
    }
  }
  .projectImpossible {
    .color {
      color: red;
    }
    .headingForImage {
      font-size: 70px;
      left: 25%;
      position: absolute;
      text-shadow: 2px 2px 4px black;
    }
    .iHeading {
      font-size: 70px;
      color: red;
    }
    .detailsSection {
      .quoteHeading {
        margin: 0px;
      }
    }
  }
}

.projectMapBlack {
  width: 100%;
  height: 100vh;
  position: relative;
}
.projectMapWhite {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
}
.largeScreen {
  font-family: Garamond, "Times New Roman", serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  .tab-content {
    display: block;
  }
}
.tab-content {
  padding: 20px;
  color: white;
}

.desktopbottomNav {
  display: flex;
  position: absolute;
  padding: 20px !important;
  bottom: 0;
  left: 0;
  width: 100%;

  h1 {
    color: white;
    padding: 0px 0px 0px 20px;
  }

  .nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-item a {
      color: white;
      font-size: large;
      background-color: transparent;
      border-radius: 0%;
      margin-right: 20px;
    }
  }
}
.mobilebottomNav {
  display: flex;
  position: absolute;
  padding: 20px !important;
  bottom: 0;
  left: 0;
  width: 100%;

  h1 {
    padding: 0px 0px 0px 20px;
  }

  .nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-item a {
      font-size: large;
      background-color: transparent;
      border-radius: 0%;
      margin-right: 20px;
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show {
  border: 1px solid red;
  .largeScreen {
    background-image: none;
  }
  .tab-content {
    display: block;
  }
}

.projectDetails {
  .detailsLarge {
    .firstSection {
      img {
        width: 100%;
        height: 35vh;
        object-fit: cover;
        object-position: center bottom;
      }
    }

    .secondSection {
      img {
        width: 100%;
        height: 75vh;
        object-fit: fit;
      }
    }

    .detailContent {
      padding: 10px 10px !important;
    }
  }

  .detailsMedium {
    margin-top: 20px;

    .firstImage {
      width: 100%;

      img {
        width: 98%;
        height: 30vh;
        object-fit: cover;
        object-position: center bottom;
        margin: 10px;
      }
    }

    .details {
      p {
        padding: 10px;
        margin: 10px 0px;
        color: white;
      }
    }

    .secondImage {
      img {
        margin: 10px;
        width: 98%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mobilebottomNav {
    justify-content: center;
    position: relative;
    align-items: center;
    .nav {
      padding: 0px;
    }
    .nav-items .nav-item a {
      margin-right: 0%;
    }

    h1 {
      text-align: center;
      padding: 0px;
    }
  }

  .mobileTabContents {
    h1 {
      text-align: center;
    }
    .headQuote {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      text-align: justify;
    }
    display: flex;
    flex-direction: column-reverse;
    .projectImpossible {
      .imageSec {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
      }
      .iHeading {
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .headingForImage {
        display: flex;
        justify-content: center;
        left: 0;
        position: relative;
      }
    }
  }
}
