.mobileMap {
  background-color: black;
  height: 100vh;
  width: 100%;
  font-family: Garamond, "Times New Roman", serif;

  .title {
    color: white;
    font-size: x-large;
    margin-top: 20px;
    text-align: center;
  }

  .tabs {
    .nav-tabs .nav-link {
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
    }
    .nav-tabs .nav-link.active {
      border: 1px solid #ff0000;
      background-color: black;
    }
    .nav-tabs {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      border: none;
      button {
        font-size: larger;
        margin-right: 10px;
        color: white;
      }
    }
  }
}
