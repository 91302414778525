$imageUrl: url("../../public/Assets/Projects/AIProjects/airThaneMap.jpg");

.projectMapBlack {
  background-color: black;
  width: 100%;
  height: 100vh;
  position: relative;
  height: 100vh;
}
.projectMapImage {
  background-image: $imageUrl;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  height: 100vh;
}

.mapLarge {
  .tab-content {
    padding: 20px;
    color: white;
  }

  .bottomNav {
    display: flex;
    position: absolute;
    padding: 20px !important;
    bottom: 0;
    left: 0;
    width: 100%;

    h1 {
      color: white;
      padding: 0px 0px 0px 20px;
    }

    .nav-items {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-item a {
        color: white;
        font-size: large;
        background-color: transparent;
        border-radius: 0%;
        margin-right: 20px;
      }
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show {
    border: 1px solid red;
  }
}

.mapMedium {
  .nav-tabs {
    margin-top: 10px;
    border: none;
    button {
      margin-left: 10px;
      color: white;
      font-size: larger;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      //   border: 1px solid #ff0000;
      //   border-radius: none;
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: black;
    color: white;
    border: 1px solid #ff0000;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
  }
}
