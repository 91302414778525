.onGoingDetails {
    height: 100vh;
    background-color: black;
}

.onGoingDetailsContainer {
    color: white;
    padding-top: 1%;
    background-color: black;
}

// .onGoingfirstRow {
//     margin-right: 0px !important;

//     .detailContainer {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         height: 80vh;
//         // .firstSection {
//         //   margin-bottom: auto;
//         // }
//         // .secondSection {
//         //   margin-top: auto;
//         // }
//     }
// }


.onGoingProjectName {
    font-size: xx-large;
    padding-left: 2% !important;
}

// .detailsValue {
//     margin-left: 10%;
// }


.onGoingSecondRow {
    margin-right: 0px !important;
    // background-color: black;
}

/* For Desktop View */
@media screen and (min-device-width: 1024px) {
    .onGoingfirstRow {
        margin-right: 0px !important;

        .detailContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80vh;
            // .firstSection {
            //   margin-bottom: auto;
            // }
            // .secondSection {
            //   margin-top: auto;
            // }
        }
    }

    .imageSection img {
        // width: auto;
        width: 100%;
        height: 80vh;
        object-fit: cover;
    }
    
}

/* For Mobile and Tablet View */
@media screen and (max-device-width: 1023px) {
    .onGoingDetails {
        height: auto;

        background-color: black;
    }

    .onGoingDetailsDiv {
        display: flex;
        flex-direction: column-reverse;
    }

    .onGoingProjectName {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .onGoingfirstRow {
        display: flex;
        flex-direction: column;
    }

    .imageSection {
        width: auto !important;
    }

    .imageSection img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .detailContainer {
        display: flex;
        flex-direction: column;
        width: auto !important;
    }
}

// /* For Mobile Phones Portrait or Landscape View */
// @media screen and (max-device-width: 767px) {
//     .navBrand {
//         flex: 1;
//     }
// }