.projectCarousel {
  padding: 0px 50px;
  a {
    text-decoration: none;
  }
  .carousel-control-next {
    height: 0%;
    width: 5%;
    top: 40%;
  }

  .carousel-control-prev {
    height: 0%;
    width: 5%;
    top: 40%;
  }

  .card-title,
  .card-text {
    color: white !important;
  }

  .odd {
    img {
      height: 60vh;
      width: 20vw;
      object-fit: cover;
    }
  }

  .even {
    img {
      width: 20vw;
      height: 45vh;
      object-fit: cover;
    }
  }

  .carousel-item .card img {
    -webkit-filter: grayscale(50%);
    filter: grayscale(50%);
  }

  .carousel-item .card:hover {
    cursor: pointer;

    -webkit-transform: scale(0.1);
    transform: scale(1.1);
    transition: 1s;

    .card-img-top {
      background-color: red;
      -webkit-filter: grayscale(00%);
      filter: grayscale(00%);
      transition: 1s;
    }
  }
}

.mobileCard {
  a {
    text-decoration: none;
  }
  .card {
    color: white;
    background-color: black;
    border: 1px solid white !important;
    margin: 10px;

    img {
      height: 50vh;
      object-fit: cover;

    }
  }
}


.mobileRow {
  flex-wrap: nowrap !important;
  align-items: center;
}